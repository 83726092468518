import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Main from "../components/Main";
import { useEffect, useState } from "react";
import { projectFirestore } from "../firebase/config";

const Home = () => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setIsPending(true);

    projectFirestore
      .collection("users")
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          setError("no users to load");
          setIsPending(false);
        }
        const results = [];
        snapshot.docs.forEach((doc) => {
          results.push({ id: doc.id, ...doc.data() });
        });
        setData(results);
        setIsPending(false);
      })
      .catch((err) => {
        setError(err.message);
        setIsPending(false);
      });
  }, []);

  return (
    <div>
      <Header />
      <Main />
      <Footer />
    </div>
  );
};

export default Home;
