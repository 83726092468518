import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <div>
      <Header />
      <Box py={10}>
        <Grid
          container
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h3" color="black" align="center">
              GET IN TOUCH
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              color="black"
              align="center"
              marginTop={3}
              maxWidth="sm"
            >
              Thanks in advance for leaving any questions, complaints,
              compliments,{" "}
              <Box component="span" sx={{ fontStyle: "italic" }}>
                etc.
              </Box>
              , about the site or its contents.{" "}
            </Typography>
            <Typography
              variant="h5"
              color="black"
              align="center"
              marginTop={3}
              marginBottom={5}
              maxWidth="sm"
            >
              Messages (in any language) are welcome at:{" "}
              <Link variant="inherit" to={`mailto:gloskosmos@protonmail.com`}>
                gloskosmos@protonmail.com
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </div>
  );
};

export default Contact;
