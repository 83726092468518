import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";

const RussianData = () => {
  const summaryOfFindings = [
    "-Большинствo русских слов имеет 7 букв.",
    "-Безусловно, самая распространенная первая буква в русских словах - 'п'.",
    "-Cамая распространенная последняя буква в русских словах - 'ь' (отчасти потому что большинство русских глаголов в инфинитиве заканчивается на эту букву) а затем 'й' (частично из-за того что все русские прилагательные в мужском роде заканчиваются на эту букву).",
    "-Буква русского языка которая встречается чаще всего в русских словах - гласный 'о', а затем согласный 'т'.",
    "-Многие из самых распространенных русских слов являются союзами, предлогами, и местоимениями. Однако, таких слов в русском очень мало, так что, поскольку список слов расширяется, существительные, ниприлагательные, наречия, и глаголы станут большинством.",
  ];
  return (
    <div>
      <Header />
      <Container maxWidth="md">
        <Box py={2}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Typography
                variant="h4"
                color="black"
                align="center"
                sx={{ marginTop: 6 }}
              >
                ЧАСТОТА СЛОВ И БУКВ В РУССКОМ ЯЗЫКЕ{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginBottom: 5 }}
              >
                АНАЛИЗ ДАННЫХ С ИСПОЛЬЗОВАНИЕМ PYTHON (PANDAS И MATPLOTLIB){" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h7"
                color="black"
                align="left"
                sx={{ fontStyle: "italic" }}
              >
                Набор данных использованный в этом анализе находится{" "}
                <a href="https://ru.wiktionary.org/wiki/%D0%9F%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5:%D0%A1%D0%BF%D0%B8%D1%81%D0%BE%D0%BA_%D1%87%D0%B0%D1%81%D1%82%D0%BE%D1%82%D0%BD%D0%BE%D1%81%D1%82%D0%B8_%D0%BF%D0%BE_%D0%9D%D0%9A%D0%A0%D0%AF">
                  здесь
                </a>
                . Анализ доступен по лицензии{" "}
                <a href="https://www.apache.org/licenses/LICENSE-2.0">
                  Apache 2.0
                </a>{" "}
                .
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="center">
                - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
                - -
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black">
                Набор данных используемый в настоящем анализе содержит список{" "}
                <strong>10,000</strong> самых распространенных русских слов,
                ранжированных по частоте их появления в Национальном Кoрпусе
                рyсского языкa <strong>(НКРЯ 10,000)</strong>. Вместе эти 10,000
                слов покрывают <strong>85%</strong> всех текстов корпуса. На
                основе этого списка, <strong>20</strong> наиболее часто
                встречающимися словами в русском языке являются следующие (в{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Таблице 1
                </Box>
                ) ниже.{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  (Примечание:
                </Box>{" "}
                Количество слов в <strong>НКРЯ 10,000</strong> чуть меньше
                количествa слов{" "}
                <a href="https://www.bbc.com/russian/russia/2010/06/100604_russian_language_day">
                  (11,000 - 13,000)
                </a>{" "}
                которые знает большинство носителей русского языка - если
                'слово' значит{" "}
                <a href="https://ru.wikipedia.org/wiki/%D0%9B%D0%B5%D0%BC%D0%BC%D0%B0_(%D0%BB%D0%B8%D0%BD%D0%B3%D0%B2%D0%B8%D1%81%D1%82%D0%B8%D0%BA%D0%B0)">
                  'лемма'
                </a>
                )
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={3} xs={12}>
                <img
                  src="/images/ru_table1.png"
                  alt="ru_table1"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                Количество Букв в Русских Словах{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Было бы интересно определить среднюю длину русского слова. Для
                этого мы можем создать новый столбец в таблице под названием
                «количество букв», который содержит количество букв в каждом из{" "}
                <strong>10,000</strong> слов. Первые <strong>20</strong> строк
                таблицы показаны ниже в{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Таблице 2
                </Box>
                :
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={4} xs={12}>
                <img
                  src="/images/ru_table2.png"
                  alt="ru_table2"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Используя функцию Python mean(), мы можем теперь найти среднее
                значение столбца «количество букв». Мы видим, что среднее
                русское слово имеет <strong>8.0186</strong> букв (как показано в
                примере кода ниже):
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={7} xs={12}>
                {" "}
                <img
                  src="/images/ru_codesample1.png"
                  alt="codesample"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                А теперь можем создать гистограммy значений встречающихся
                наиболее часто в столбце «количество букв» (т. е. статистическая
                мода) и видим результат на{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Диаграмме 1
                </Box>{" "}
                - Однако срeдним значением является <strong>восемь</strong>,
                модой является <strong>семь</strong> (т. е. большинство русских
                слов -<strong> 1,447 </strong>- имеет <strong>семь</strong>{" "}
                букв):
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={8} xs={12}>
                <img
                  src="/images/ru_figure1.png"
                  alt="figure1"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Приведенный ниже код показывает некоторые из самых длинных слов
                русского языка, которые знаeт большинство носителей языка
                (конечно, есть более длинные слова, которые менее
                распространены). Два самых длинных слова (без знака тире) в
                списке <strong>НКРЯ 10,000</strong> — «
                <strong>сельскохозяйственный</strong>» (<strong>20</strong>{" "}
                букв) и «<strong>конкурентоспособность</strong>» (
                <strong>21 </strong>
                буква).
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={6} xs={12}>
                <img
                  src="/images/ru_codesample2.png"
                  alt="ru_codesample2"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                Первая Буква Русских Слов{" "}
              </Typography>
            </Grid>
            <Grid item>
              Также интересно наблюдать с какой буквы русские слова чаще всего
              начинаются. Для этого, можем создать новый столбец в таблице под
              названием "первая буква" (как показано в{" "}
              <Box component="span" sx={{ textDecoration: "underline" }}>
                Таблице 3
              </Box>{" "}
              ниже):
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={5} xs={12}>
                {" "}
                <img
                  src="/images/ru_table3.png"
                  alt="ru_table3"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              Отсюда можем создать новую столбчатую диаграмму{" "}
              <Box component="span" sx={{ textDecoration: "underline" }}>
                (Диаграмма 2)
              </Box>{" "}
              который показывает самую распространенную первую букву в русских
              словах и отметим, что <strong>1,719</strong> русских слов
              начинаются с coгласного 'п' (напр. слова с префиксами 'пред-',
              'при-', 'пре-', 'про-', и 'по-') и <strong>1,069</strong> слов
              начинаются с coгласного 'с'. C другой стороны только{" "}
              <strong>четыре</strong> слова (напр. 'ёжик') начинаются с гласного
              'ё' и никакого слова начинается с полугласного 'й' или гласного
              'ы'. (
              <Box component="span" sx={{ textDecoration: "underline" }}>
                Примечание:
              </Box>{" "}
              хотя как правило русские слова не могут начинаться с 'ы', есть
              слова которые начинаются с 'й', например 'йога' или 'йогурт' но
              они менее распространены и не в списке{" "}
              <strong>НКРЯ-10,000</strong>).
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={8} xs={12}>
                {" "}
                <img
                  src="/images/ru_figure2.png"
                  alt="figure2"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                Последняя Буква Русских Слов{" "}
              </Typography>
            </Grid>
            <Grid item>
              Из набора данных также можем наблюдать какой буквой русские слова
              чаще всего заканчиваются. Для этого, можем создать новый столбец в
              таблице под названием "последняя буква" (как показано в{" "}
              <Box component="span" sx={{ textDecoration: "underline" }}>
                Таблице 4
              </Box>{" "}
              ниже):
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={6} xs={12}>
                {" "}
                <img
                  src="/images/ru_table4.png"
                  alt="lastLetter"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Отсюда можем создать новую столбчатую диаграмму{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  (Диаграмма 3)
                </Box>{" "}
                который показывает самую распространенную последнюю букву в
                русских словах и отметим, что <strong>2,433</strong> русских
                слова заканчиваются мягким знаком 'ь' и <strong>1,894</strong>{" "}
                словa заканчиваются полугласным 'й'. Это имеет смысл так как все
                невозвратныe русские глаголы в инфинитиве заканчиваются первым и
                все русские прилагательные в мужском роде заканчиваются
                последним. Кроме того все возвратные глаголы зканчиваются
                гласным 'я' (<strong>1,035</strong> раз в списке) и многие
                женские существительные заканчиваются гласным 'а' (
                <strong>1,058</strong> раз в списке).
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                C другой стороны только <strong>два</strong> слова (т.е.
                междометия 'э' - <strong>№ 5,076</strong> в списке - и 'э-э' -{" "}
                <strong>№ 7,462</strong>) заканчиваются буквой 'э' и{" "}
                <strong>три</strong> заканчиваются буквой 'щ' (напр. 'товарищ'{" "}
                <strong>-№ 7,496</strong>). Кроме того с реформы орфографии 1918
                года никакого слова заканчивается твёрдым знаком 'ъ'. (
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Примечание:
                </Box>{" "}
                Cписок <strong>НКРЯ-10,000</strong> содержит только леммы так
                что включает в себя слова как 'показать' и 'хороший' но не те
                как 'покажу' и 'хорошая'.)
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={7} xs={12}>
                {" "}
                <img
                  src="/images/ru_figure3.png"
                  alt="figure3"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                Самая Распространенная Буква в Русском Языке{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Ещё одним интересным анализом является определение, на основе
                списка, какие буквы русского алфавита встречаются чаще всего в
                списке <strong>НКРЯ-10,000</strong>. Для этого можем создать
                словарь Python для каждой буквы алфавита, в котором каждая буква
                является ключом, а значение - сколько раз буква появляется в
                списке. Из вновь созданного словаря можем создать ещё одну
                диаграмму (
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Диаграмма 4
                </Box>
                ) с четырьмя разными цветами, чтобы различать гласные (красный)
                согласные (зелёный), знаки (синий), и единственный полугласный
                'й' (жёлтый).
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={8} xs={12}>
                {" "}
                <img
                  src="/images/ru_figure4.png"
                  alt="figure4"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Диаграмма 4
                </Box>{" "}
                показывает, что буква 'o' является наиболее часто встречающейся
                буквой в списке <strong>НКРЯ 10,000</strong> (
                <strong>7,216</strong> раз). Твёрдый знак 'ъ' встречается меньше
                всего (<strong>43</strong> раза) а затем следует согласный 'э' (
                <strong>114</strong> раз).
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Среди гласных диаграмма также показывает что четыре гласных 'а'
                (<strong>6,525</strong> раз), 'е' (<strong>5,505</strong> раз),
                'и' (<strong>5,499</strong> раз), и 'о' (<strong>7,216</strong>{" "}
                раз) встречаются заметно больше чем остальные шесть гласных (и
                чем полугласный 'й' - показан выше жёлтым цветом).
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Буква 'т' занимает первое место среди согласных (
                <strong>6,659</strong> раз), а затем следует 'н' (
                <strong>5,149</strong> раз), 'с' (<strong>4,704</strong> раза) и
                'р' (<strong>4,548</strong> раз). 'ф' занимает последнее место (
                <strong>245</strong> раз) а затем 'щ' (<strong>255</strong>{" "}
                раз).
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                Части Pечи Русскиx Слов{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Ещё один интересный анализ — определить самые распространенные
                части речи в списке <strong>НКРЯ-10000</strong>. Список не
                включает части речи каждого слова, но мы можем это добавить
                вручную (или через API) для первых <strong>200</strong> слов в
                списке. С помощью этого нового столбца мы можем создать пару
                круговых диаграмм с результатами.
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                color="black"
                align="center"
                sx={{ textDecoration: "underline", marginTop: 5 }}
              >
                Части Pечи 20 Самых Используемыx Русскиx Слов{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Круговая диаграмма (
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Диаграмма 5
                </Box>
                ) ниже показывает часть речи <strong>20</strong> самых
                используемыx русскиx слов. (
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Примечание:
                </Box>{" "}
                В списке слова имеющие две разныe части речи появляются дважды в
                списке - например 'что' (союз) занимает <strong>9-е</strong>{" "}
                место и 'что' (местоимение) занимает <strong>26-е</strong>).
                Первые <strong>20</strong> слов в списке включают{" "}
                <strong>пять союзов </strong>
                (т.e., 'и', 'а', 'что', 'но', и 'как'),{" "}
                <strong>шесть предлогов </strong> (т.e., 'в', 'на', 'с', 'по',
                'к', и 'из'), <strong>один глагол</strong> (т.e., 'быть'),{" "}
                <strong>семь местоимений</strong> (т.e., 'я', 'он', 'это',
                'она', 'этот', 'они', и 'мы'), и<strong> одно наречие</strong>{" "}
                (т.e., 'не'). Интересно, что ни{" "}
                <strong> существительные</strong> ни{" "}
                <strong>прилагательные</strong> не появляются среди первых{" "}
                <strong>20</strong> слов списка.
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={8} xs={12}>
                {" "}
                <img
                  src="/images/ru_figure5.png"
                  alt="figure5"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                color="black"
                align="center"
                sx={{ textDecoration: "underline", marginTop: 5 }}
              >
                Части Pечи 200 Самых Используемыx Русскиx Слов{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h8" color="black" align="left">
                Когда список расширяется до первых <strong>200</strong> слов,
                круговая диаграмма{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  (Диаграмма 6)
                </Box>{" "}
                существенно меняется. Существительные, глаголы, прилагательные и
                наречия теперь составляют свыше <strong>двух третей</strong>{" "}
                круговой диаграммы, тогда как предлоги, союзы, и местоимения
                составляют <strong>меньшинство</strong>. Есть тоже некоторые
                предикативы (напр. 'надо'), междометия (напр. 'вот'), и частицы
                (напр. 'бы'). Это имеет смысл так как в русском языке есть
                тысячи существительных, глаголов и прилагательных (и их число
                растёт с каждым годом) и намного меньше местоимений, предлогов,
                союзов, предикативов, междометий, и частиц, (число которых
                остаётся стабильным со временем). Следует отметить, что самое
                распространенное существительное и прилагательнoe в русском
                языке, соответственно, - 'человек' (<strong>№ 39</strong> в
                списке) и 'весь' (<strong>№ 27</strong> в списке) .
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={9} xs={12}>
                {" "}
                <img
                  src="/images/ru_figure6.png"
                  alt="figure6"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 10 }}
              >
                РЕЗЮМЕ ВЫВОДОВ{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left" p={2}>
                Наиболее важными выводами этого анализа являются следующие:
              </Typography>
              <List>
                {summaryOfFindings.map((summary) => (
                  <ListItem key={summary}>
                    <ListItemText sx={{ marginLeft: 2 }} primary={summary} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </div>
  );
};
export default RussianData;
