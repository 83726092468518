import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

const ArabicData = () => {
  return (
    <div>
      <Header />
      <Container maxWidth="md">
        <Box py={2}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Typography
                variant="h4"
                color="black"
                align="center"
                sx={{ marginTop: 6 }}
              >
                تحليل تردد الكلمات والحروف في اللغة العربية{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginBottom: 5 }}
              >
                تحليل بيانات مع بايثون (باندا و مات بلوت ليب){" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                color="black"
                align="left"
                sx={{ fontStyle: "italic" }}
              >
                مجموعة البيانات المستخدمة في هذا التحليل تجدها في هذا{" "}
                <a href="http://www.modernstandardarabic.com/top-50000-arabic-words/">
                  الرابط.
                </a>
                {"  "}
                التحليل متاح في إطار{" "}
                <a href="https://www.apache.org/licenses/LICENSE-2.0">
                  رخصة أباتشي 2.0.
                </a>{" "}
                <span style={{ color: "white", userSelect: "none" }}>ة</span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="center">
                - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
                - -
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="black" align="right">
                تحتوي مجموعة البيانات المستخدمة في هذا التحليل على{" "}
                <strong>50,000</strong> الكلمات الأكثر شيوعًا في اللغة العربية،
                مرتبة حسب عدد المرات استخدامها في وسائل الإعلام والمنشورات. يمكن
                تقصير القائمة بحيث تتضمن <strong>20,000</strong> كلمات (يساوي
                تقريبًا عدد الكلمات التي يعرفها معظم المتحدثين باللغة العربية)
                بناء على هذه القائمة، <strong>20</strong> الكلمات الأكثر شيوعًا
                في اللغة العربية{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  (الجدول ١)
                </Box>{" "}
                هي التالية:
                <span style={{ color: "white", userSelect: "none" }}>ة</span>
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={5} xs={12}>
                <img
                  src="/images/ar_table1.png"
                  alt="ar_table1"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                عدد الحروف في الكلمات العربية{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="black" align="right">
                من المثير للاهتمام تحديد متوسط ​​طول الكلمة العربية. ولهذا يمكن
                إنشاء عمود جديد مع اسم "عدد الحروف" الذي يحتوي على عدد الحروف في
                كل كلمة في القائمة. ترد في{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  الجدول ٢{" "}
                </Box>{" "}
                أدناه الصفوف <strong>العشرين</strong> الأولى:{" "}
                <span style={{ color: "white", userSelect: "none" }}>ة</span>
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={4} xs={12}>
                <img
                  src="/images/ar_table2.png"
                  alt="ar_table2"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="black" align="right">
                باستخدام وظيفة بايثون، نستطيع الآن حساب متوسط ​​العمود "عدد
                الحروف". يمكن أن نرى أن الكلمة العربية المتوسطة تحتوي على{" "}
                <strong>5.1019</strong> حروف (كما هو موضح في العينة التعليمات
                البرمجية أدناه):{" "}
                <span style={{ color: "white", userSelect: "none" }}>ة</span>
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={7} xs={12}>
                {" "}
                <img
                  src="/images/ar_codesample1.png"
                  alt="codesample"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="black" align="right">
                والآن يمكن إنشاء مخطط شريطي للقيم الأكثر حدوثاً في عمود "عدد
                الحروف" (يعني المنوال). يمكن أن نرى النتيجة في{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  الشكل ١
                </Box>
                - معظم الكلمات العربية (<strong>5,713</strong>) تحتوي على{" "}
                <strong>خمسة</strong> حروف.
                <span style={{ color: "white", userSelect: "none" }}>ة</span>
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={8} xs={12}>
                <img
                  src="/images/ar_figure1.png"
                  alt="figure1"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="black" align="right">
                يكشف العينة التعليمات البرمجية أدناه عن أطول كلمة باللغة العربية
                يعرفها معظم المتحدثين الأصليين (بالطبع هناك كلمات أطول بكثير لكن
                هي أكثر غموضاً). الكلمة الأطول بالعربية هي "
                <strong>الإمبراطورية</strong>" (<strong>12</strong> حرفًا):
                <span style={{ color: "white", userSelect: "none" }}>ة</span>
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={6} xs={12}>
                <img
                  src="/images/ar_codesample2.png"
                  alt="ar_codesample2"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                الحروف الأولى في الكلمات العربية{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="black" align="right">
                ومن المثير للاهتمام أيضًا مراقبة ما هو الحرف الأول في معظم
                الكلمات في اللغة العربية. لهذا يمكن إنشاء عمود جديد مع اسم
                "الحرف الأول" (كما هو موضح في{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  الجدول ٣
                </Box>{" "}
                أدناه):
                <span style={{ color: "white", userSelect: "none" }}>ة</span>
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={5} xs={12}>
                {" "}
                <img
                  src="/images/ar_table3.png"
                  alt="ar_table3"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="black" align="right">
                من هنا يمكن إنشاء مخطط شريطي{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  (الشكل ٢)
                </Box>{" "}
                يعرض الحروف الأولى الأكثر شيوعًا في الكلمات العربية. و يمكن
                ملاحظة النتيجة - الحرف "أَلِف" أوْ "ا" هو الحرف الأول حد بعيد
                الأكثر شيوعًا (في <strong>5,003</strong> من أصل{" "}
                <strong>20,000</strong> كلمة) في اللغة العربية (وبعد ذلك هناك
                "أ" - <strong>1,523</strong> كلمة - و "ت" -{" "}
                <strong>1,507</strong> كلمة). هذا منطقي لأن كل اسم معرّف باللغة
                العربية يبدأ بأداة التعريف "ال" .ومن ناحية أخرى في المركز الأخير
                هناك "ظ"، (<strong>31</strong> كلمة) الحرف الأول الأقل شيوعًا،
                وبعد ذلك الحرف "ث" (<strong>58</strong> كلمة).
                <span style={{ color: "white", userSelect: "none" }}>ة</span>
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={8} xs={12}>
                {" "}
                <img
                  src="/images/ar_figure2.png"
                  alt="figure2"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                الحروف الأخيرة في الكلمات العربية{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="black" align="right">
                يمكن أيضا أن تحدد ما هو الحرف الأخير في معظم الكلمات في اللغة
                العربية. هذه المرة ننشئ عمود جديد مع اسم "الحرف الأخير" (كما هو
                موضح في{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  الجدول ٤
                </Box>{" "}
                أدناه):
                <span style={{ color: "white", userSelect: "none" }}>ة</span>
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={6} xs={12}>
                {" "}
                <img
                  src="/images/ar_table4.png"
                  alt="lastLetter"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="black" align="right">
                من هنا يمكن إنشاء مخطط شريطي أخر (
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  الشكل ٣
                </Box>
                ). هذه المرة المخطط يظهر الحروف الأخيرة الأكثر شيوعًا في الكلمات
                العربية. يمكن أن نرى أن الحرف الأخير الأكثر شيوعًا (
                <strong>2,623</strong> كلمة) هو- "ة" (الذي يوجد في نهاية معظم
                الأسماء و الصفات المؤنثة)، يليه الحرف "ي" (
                <strong>2,010</strong> كلمة)، الذي يوجد في نهاية العديد من
                الصفات بصيغة المذكر. من ناحية أخرى كلمة <strong>واحدة</strong>{" "}
                فقط تنتهي بحرف "إ" (يعني الكلمة "إ" في المرتبة{" "}
                <strong>16,867#</strong> في القائمة). هي الشخص الثاني المفرد
                المذكر من الفعل "وَأَى". بالإضافة إلى ذلك، <strong>ثلاث</strong>{" "}
                كلمات تنتهي بحرف "ؤ" ("يجرؤ" - <strong>13,484#</strong>،
                "التنبؤ" - <strong>10,914#</strong>، و "تجرؤ" -{" "}
                <strong>4,461#</strong>).
                <span style={{ color: "white", userSelect: "none" }}>ة</span>
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={8} xs={12}>
                {" "}
                <img
                  src="/images/ar_figure3.png"
                  alt="figure3"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                الحرف الأكثر شيوعاً في اللغة العربية{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="black" align="right">
                تحليل آخر مثير للاهتمام هو تحديد الحرف الذي يظهرغالبًا في
                الكلمات العربية. لهذا يمكن إنشاء قاموس بايثون لكل حرف من الحروف
                الأبجدية حيث كل حرف هو المفتاح والقيمة هي عدد المرات التي يظهر
                فيها الحرف في قائمة <strong>20,000</strong> كلمة. من هذا القاموس
                المنشأة حديثاً يمكن إنشاء مخطط شريطي آخر{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  (الشكل ٤)
                </Box>{" "}
                مع ثلاثة ألوان مختلفة: أحمر (للحروف العلة)، أخضر (للحروف
                الساكنة)، وأزرق (للهمزة)، كما هو موضح أدناه:
                <span style={{ color: "white", userSelect: "none" }}>ة</span>
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={8} xs={12}>
                {" "}
                <img
                  src="/images/ar_figure4.png"
                  alt="figure4"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="black" align="right">
                يبين{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  الشكل ٤
                </Box>{" "}
                أن حد بعيد الحرف الأكثر شيوعاً في الكلمات العربية هو الحرف العلة
                "ا" (أَلِف) الذي يظهر <strong>13,693</strong> مرة في{" "}
                <strong>20,000</strong> الكلمات الأكثر شيوعًا. الحرف الأقل
                شيوعًا هو الحرف العلة "ؤ" (همزة فوق الواو - <strong>120</strong>{" "}
                مرة) يليه الحرف العلة "آ" ( ألف ممدودة - <strong>145</strong>{" "}
                مرة).
                <span style={{ color: "white", userSelect: "none" }}>ة</span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="black" align="right">
                فيما بين الحروف الساكنة الحرف "ل" يحتل المرتبة الأولى (
                <strong>10,010</strong> مرة). يليه ''ت" (<strong>5,992</strong>{" "}
                مرة) و "ر" (<strong>5,454</strong> مرة). الحرف الأقل شيوعًا هو
                "ظ" (<strong>352</strong> مرة) وبعد ذلك "ث" (
                <strong>506</strong> مرة ). بين هذين الحرفين هو الهمزة (
                <strong>404</strong> مرة).
                <span style={{ color: "white", userSelect: "none" }}>ة</span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="black" align="right">
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  ملاحظة
                </Box>
                : الحرفان "ة" و "ى" يظهران بنفس عدد المرات في الشكلان ٣ و ٤ (
                <strong>2,623</strong> و <strong>591</strong> على التوالي) لأن
                كلا يتواجدان فقط في نهاية الكلمة.
                <span style={{ color: "white", userSelect: "none" }}>ة</span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                أقسام الكلام للكلمات العربية{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="black" align="right">
                في تحليل أخير يمكن ملاحظة ما هي أقسام الكلام للكلمات الأكثر
                شيوعا في اللغة العربية. لا تشمل القائمة التي تم تنزيلها أقسام
                الكلام لكن يمكن إضافتها يدويًا أو من خلال واجهة برمجة تطبيقات.
                باستخدام هذا العمود الجديد يمكن إنشاء بضعة مخططات دائرية مع
                النتائج.
                <span style={{ color: "white", userSelect: "none" }}>ة</span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                color="black"
                align="center"
                sx={{ textDecoration: "underline", marginTop: 5 }}
              >
                أقسام الكلام لعشرين كلمة الأكثر شيوعا في اللغة العربية{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="black" align="right">
                المخطط الدائري أدناه (
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  الشكل ٥
                </Box>
                ) يظهر أقسام الكلام <strong>لعشرين</strong> كلمة الأكثر شيوعا في
                اللغة العربية. هي التالية: <strong>أربع أدوات</strong> ("لا" و
                "هل" و "لم" و "لقدْ")، <strong>أربعة حروف الجر</strong> ("من" و
                " في" و "على" و "إلى")، <strong>سبعة ضمائر</strong> ( "هذا" و
                "ما" و "أنا" و "ذلك" و "ماذا" و "أنت" و "هو")،{" "}
                <strong>حرْفا عطفٍ</strong> ("أن" و "و")،{" "}
                <strong>فعل واحد</strong> ("كان")،{" "}
                <strong>حرف نداء واحد</strong> ("يا")، و
                <strong> ظرف واحد</strong> ("هنا"). ومن المثير للاهتمام ليس هناك
                أسماء بين عشرين كلمة الأكثر شيوعا.
                <span style={{ color: "white", userSelect: "none" }}>ة</span>
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={9} xs={12}>
                {" "}
                <img
                  src="/images/ar_figure5.png"
                  alt="figure5"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                color="black"
                align="center"
                sx={{ textDecoration: "underline", marginTop: 5 }}
              >
                أقسام الكلام لمائتين كلمة الأكثر شيوعا في اللغة العربية{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="black" align="right">
                عندما تتوسع القائمة إلى مائتين كلمة الأكثر شيوعًا ، يتغير المخطط
                الدائري{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  (الشكل ٦)
                </Box>{" "}
                بشكل ملحوظ. تشكل الأسماء والأفعال والصفات والظروف الآن{" "}
                <strong>اكثر من نصف</strong> المخطط الدائري، حين أن الأدوات و
                الضمائر و حروف العطف تشكل <strong>الأقلية</strong>. هذا منطقي
                لأنه في اللغة العربية هناك الآلاف من كلمات المحتوى (يعني الأسماء
                والأفعال والصفات والظروف) ويزداد عدد هذه الكلمات كل عام. من
                ناحية أخرى ، هناك عدد قليل جدًا من كلمات وظيفية (يعني الضمائر
                وحروف الجر و الأدوات وحروف العطف) و ظلت أعدادها ثابتة على مر
                السنين. ومن الجدير بالذكر أيضا أن الاسم الأكثر شيوعًا في اللغة
                العربية هو" شَيء" (<strong>33#</strong> في القائمة) و الصفة
                الأكثر شيوعًا هي "صحيح" (<strong>85#</strong> في القائمة) على
                الرغم من الناحية الفنية بعض الكلمات التي هي أكثر شيوعا في بعض
                الحالات يمكن اعتبارها صفات أو أسماء (على سبيل المثال الكلمة "كل"
                -<strong>27#</strong> أو "هذا" - <strong>5#</strong>).
                <span style={{ color: "white", userSelect: "none" }}>ة</span>
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={9} xs={12}>
                {" "}
                <img
                  src="/images/ar_figure6.png"
                  alt="figure6"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="black" align="right">
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  ملاحظة:
                </Box>{" "}
                بعض الكلمات الواردة في{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  {" "}
                  الشكل ٦
                </Box>{" "}
                هي مجموعة من أقسام الكلام متعددة (مثل "كذلك" -{" "}
                <strong>58#</strong> - هي ضمير + حرف جر٫ أو "وأنا" -{" "}
                <strong>149#</strong> - هي حرف عطف + ضمير). في هذه الحالات قسم
                الكلام الأول في المجموعة يعتبر قسم الكلام الوحيد لأغراض{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  الشكل ٦
                </Box>
                . لذلك بعض فئات في{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  الشكل ٦
                </Box>{" "}
                (و خصوصا الفئتان " حرف جر" و "حرف عطف") هي غير دقيقة قليلا (ولكن
                المخطط الدائري لا يزال يظهر اتجاه نحو الأسماء والأفعال والصفات
                والظروف).
                <span style={{ color: "white", userSelect: "none" }}>ة</span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 10 }}
              >
                ملخص الاستنتاجات{" "}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            direction="column"
            alignItems="right"
          >
            <Grid item>
              <Typography variant="h6" color="black" align="right"></Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="black" align="right">
                فيما يلي أهم نتائج هذا التحليل:
                <span style={{ color: "white", userSelect: "none" }}>
                  ة
                </span>{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="black" align="right">
                <span style={{ color: "white", userSelect: "none" }}>ا</span>
                --الكلمة العربية المتوسطة تحتوي على خمسة حروف. هذا أقل بكثير من
                اللغات مثل الانجليزية او الروسية حيث تحتوي الكلمة المتوسطة على
                سبعة و ثمانية حروف على التوالي.
                <span style={{ color: "white", userSelect: "none" }}>ة</span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="black" align="right">
                <span style={{ color: "white", userSelect: "none" }}>ا</span>
                --الحرف الأول حد بعيد الأكثر شيوعًا في اللغة العربية هو 'ا'.
                الحرف الأخير الأكثر شيوعًا في اللغة العربية هو 'ة' (جزئيًا لأنه
                يوجد في نهاية معظم الأسماء و الصفات المؤنثة)، يليه الحرف 'ي'
                (الذي يوجد في نهاية العديد من الصفات بصيغة المذكر).
                <span style={{ color: "white", userSelect: "none" }}>ة</span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="black" align="right">
                <span style={{ color: "white", userSelect: "none" }}>ا</span>
                --حد بعيد الحرف الأكثر شيوعاً في الكلمات العربية هو الحرف العلة
                "ا". الحرف الساكن الأكثر شيوعاً هو "ل". من بين الاستخدامات
                الأخرى كل اسم معرّف باللغة العربية يبدأ بهذين الحرفان.
                <span style={{ color: "white", userSelect: "none" }}>ة</span>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" color="black" align="right">
                <span style={{ color: "white", userSelect: "none" }}>ا</span>
                --العديد من الكلمات العربية الأكثر شيوعًا هي ضمائر وحروف العطف
                وحروف الجر و أدوات. ومع ذلك هناك عدد قليل جدًا من هذه الأنواع من
                الكلمات في اللغة العربية، لذلك مع تتوسع قائمة الكلمات الأكثر
                شيوعًا هي ستشمل المزيد من الأسماء والأفعال و الظروف و الصفات.
                <span style={{ color: "white", userSelect: "none" }}>ة</span>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </div>
  );
};
export default ArabicData;
