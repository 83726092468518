import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import About from "./pages/About";
import NounTranslation from "./pages/NounTranslation";
import NounAssociation from "./pages/NounAssociation";
import NounCombination from "./pages/NounCombination";
import LanguageQuiz from "./pages/LanguageQuiz";
import ScriptsQuiz from "./pages/ScriptsQuiz";
import GrammarQuiz from "./pages/GrammarQuiz";
import EnglishData from "./pages/EnglishData";
import RussianData from "./pages/RussianData";
import ArabicData from "./pages/ArabicData";
import FrenchData from "./pages/FrenchData";
import GermanData from "./pages/GermanData";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import { AuthContextProvider } from "./context/AuthContext";
import { useAuthContext } from "./hooks/useAuthContext";

function Page() {
  const { authIsReady, user } = useAuthContext();

  return (
    <div>
      {authIsReady && (
        <BrowserRouter>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/noun-translation" element={<NounTranslation />} />
            <Route path="/noun-association" element={<NounAssociation />} />
            <Route path="/noun-combination" element={<NounCombination />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/language-quiz" element={<LanguageQuiz />} />
            <Route path="/scripts-quiz" element={<ScriptsQuiz />} />
            <Route path="/grammar-quiz" element={<GrammarQuiz />} />
            <Route path="/english-data" element={<EnglishData />} />
            <Route path="/russian-data" element={<RussianData />} />
            <Route path="/arabic-data" element={<ArabicData />} />
            <Route path="/french-data" element={<FrenchData />} />
            <Route path="/german-data" element={<GermanData />} />
            <Route path="/login" element={!user ? <Login /> : <Home />} />
            <Route path="/signup" element={!user ? <Signup /> : <Home />} />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthContextProvider>
    <Page />
  </AuthContextProvider>
);
