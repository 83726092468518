import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";

export default function PopperButton(props) {
  const { label, menuItems } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();
  const navigateToQuizGames = (menuItem) => {
    if (menuItem === "Language Families and Geography") {
      navigate("/language-quiz");
    } else if (menuItem === "Writing Systems and Phonology") {
      navigate("/scripts-quiz");
    } else if (menuItem === "Grammar and Morphology") {
      navigate("/grammar-quiz");
    } else if (menuItem === "Polyglot Challenge: Word Translation") {
      navigate("/noun-translation");
    } else if (menuItem === "Polyglot Challenge: Word Association") {
      navigate("/noun-association");
    } else if (menuItem === "Polyglot Challenge: Word Combination") {
      navigate("/noun-combination");
    } else if (menuItem === "English Word Analysis") {
      navigate("/english-data");
    } else if (menuItem === "Анализ Pусских Cлов") {
      navigate("/russian-data");
    } else if (menuItem === "Analyse des Mots Français") {
      navigate("/french-data");
    } else if (menuItem === "Deutsche Wortanalyse") {
      navigate("german-data");
    } else {
      navigate("/arabic-data");
    }
  };
  return (
    <React.Fragment>
      <Button
        variant="contained"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {label}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuItems.map((label, index) => (
          <MenuItem key={index} onClick={() => navigateToQuizGames(label)}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}
