import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

const About = () => {
  return (
    <div>
      <Header />
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h3" color="black" marginTop={7} align="center">
            About GlosKosmos
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="h4"
            color="black"
            marginTop={7}
            marginBottom={2}
            align="center"
          >
            Thank you for your visit!
          </Typography>
        </Grid>
      </Grid>
      <Container maxWidth="sm">
        <Typography
          variant="h6"
          color="black"
          marginTop={2}
          marginBottom={2}
          textAlign="center"
        >
          GlosKosmos, a portmanteau of the Greek words for 'language' and
          'world', is a website dedicated to the practice and study of
          languages. Currently most of the website's content (including the
          quizzes and the games) is in English, but more content in other
          languages will be added in the coming months.
        </Typography>
        <Typography variant="h6" color="black" marginTop={2} textAlign="center">
          Feedback on the site (including information on typos or language
          errors) is welcome and can be submitted on the 'Contact' page below.
        </Typography>
        <Typography
          variant="h6"
          color="black"
          align="center"
          marginTop={2}
          marginBottom={7}
          maxWidth="sm"
        >
          For more content from the site's author/developer, feel free to check
          out his{" "}
          <a href="https://www.youtube.com/@Lexientist">YouTube Channel</a>,
          where you will find short videos about etymology (mostly of English
          words) and historical linguistics.
        </Typography>
      </Container>
      <Footer />
    </div>
  );
};

export default About;
