import React from "react";
import { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ScoresList from "../components/ScoresList";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TranslateIcon from "@mui/icons-material/Translate";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import words from "../components/data/nounGameWords.json";
import flags from "../components/data/flags.json";
import GameOver from "../components/GameOver";
import { useFirestore } from "../hooks/useFirestore";
import { useAuthContext } from "../hooks/useAuthContext";
import { useCollection } from "../hooks/useCollection";

const generateLanguagesArray = (clickedOnLanguages = []) => {
  const languageOptions = [
    "French",
    "Spanish",
    "Portuguese",
    "German",
    "Russian",
    "Italian",
    "Polish",
    "Arabic",
    "Chinese",
    "Turkish",
    "Korean",
    "Indonesian",
    "Hindi",
    "Japanese",
  ];

  const filteredLanguages = languageOptions.filter(
    (language) => !clickedOnLanguages.includes(language)
  );
  const arrayLength =
    filteredLanguages.length > 4 ? 4 : filteredLanguages.length;
  const generateLanguageIndexesArray = () => {
    const languageIndexesArray = [...new Array(arrayLength)].map(() =>
      Math.floor(Math.random() * filteredLanguages.length)
    );
    const uniqueLanguageIndexes = [...new Set(languageIndexesArray)];
    if (languageIndexesArray.length === uniqueLanguageIndexes.length) {
      return languageIndexesArray;
    }
    return generateLanguageIndexesArray();
  };
  return generateLanguageIndexesArray().map(
    (index) => filteredLanguages[index]
  );
};

const languageSummaries = [
  "In this game you will translate words from different languages into English.",
  "Each round consists of 10 randomly generated nouns shown individually.",
  "A correct translation into English prompts another word (if submitted within 10 seconds); an incorrect translation ends the game.",
  "Once ten words are translated correctly, another language is added, and you will advance to the next level.",
  "At the bottom of the page is the list of high scores. See if you can beat any of them.",
];

const rules = [
  "-All answers are single words. However, some answers are words with dashes such as 'son-in-law' (10 letters) and 'push-up' (7 letters).",
  "-Answers are all common nouns. Therefore, no capitalization is used.",
  "-American English spelling is used for each answer (e.g., 'tire' and not 'tyre').",
  "-Except for the dash (-), answers do not include any special characters, spaces, or diacritics, so 'fiance' is considered correct, but not 'fiancé'.",
  // "-MOST IMPORTANTLY: BE SURE TO READ THE HINT (IN RED BELOW THE ‘SUBMIT’ BUTTON) BEFORE SUBMITTING AN ANSWER. IN SOME CASES, A WORD MAY HAVE TWO DIFFERENT MEANINGS (E.G., ‘FEDER’ IN GERMAN MEANS BOTH ‘FEATHER’ AND ‘SPRING’, BUT ONLY ONE OF THOSE MEANINGS WILL BE ACCEPTED AS THE CORRECT ANSWER), SO THE HINT WILL HELP TO DETERMINE WHAT THE CORRECT TRANSLATION IS.",
];

const Welcome = ({ setGameStage }) => {
  const { documents, error } = useCollection("noun-translation-high-scores");

  return (
    <Box p={2}>
      <Grid
        container
        spacing={2}
        p={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h4" color="black" align="center" p={2}>
            Polyglot Challenge: Word Translation Game
          </Typography>
          <Typography
            variant="h4"
            color="black"
            align="center"
            paddingBottom={3}
          >
            (English Version)
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="h5"
            color="black"
            align="center"
            p={2}
            paddingBottom={3}
          >
            Game Summary:{" "}
          </Typography>
          <List>
            {languageSummaries.map((summary) => (
              <ListItem key={summary}>
                <TranslateIcon />
                <ListItemText sx={{ marginLeft: 2 }} primary={summary} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            size="large"
            onClick={() => setGameStage("language-selection")}
            sx={{ marginBottom: 10 }}
          >
            Start Game
          </Button>
        </Grid>
      </Grid>

      <Typography
        variant="h6"
        sx={{ textDecoration: "underline" }}
        color="black"
        align="left"
        p={2}
      >
        Tips to remember when submitting answers:{" "}
      </Typography>
      <List>
        {rules.map((rule) => (
          <ListItem key={rule}>
            <ListItemText sx={{ marginLeft: 2 }} variant="h6" primary={rule} />
          </ListItem>
        ))}
      </List>
      <Typography
        variant="h5"
        color="black"
        align="center"
        p={2}
        paddingBottom={3}
        sx={{ textDecoration: "underline" }}
      >
        HIGH SCORES
      </Typography>
      {error && <p>{error}</p>}
      {documents && <ScoresList scores={documents} />}
    </Box>
  );
};

const LanguageSelection = ({
  setGameStage,
  clickedOnLanguages,
  setClickedOnLanguages,
  languagesMenu,
  score,
  setSelectedLanguage,
  randomLanguageIndex,
  playMultipleGames,
  setRandomLanguageIndex,
  setEnglishTranslation,
}) => {
  useEffect(() => {
    if (playMultipleGames) {
      setSelectedLanguage("");
      setRandomLanguageIndex(0);
    }
  }, [playMultipleGames, setSelectedLanguage, setRandomLanguageIndex]);

  const onLanguageSelect = (language) => {
    setEnglishTranslation("");
    setGameStage("game-in-progress");
    setRandomLanguageIndex(
      Math.floor(Math.random() * clickedOnLanguages.length)
    );
    setClickedOnLanguages((currentArray) => [language, ...currentArray]);
    const newClickedOnLanguages = [language, ...clickedOnLanguages];
    return setSelectedLanguage(newClickedOnLanguages[randomLanguageIndex]);
  };

  return (
    <Grid container direction="column" spacing={7} marginBottom={10}>
      <Grid item>
        <Typography
          variant="h5"
          color="black"
          align="center"
          sx={{ margin: 7 }}
        >
          Please Choose {score > 1 ? "Another" : "A"} Language:
        </Typography>
      </Grid>
      <Grid
        container
        item
        spacing={languagesMenu.includes("Portuguese") ? 1.25 : 2}
        alignItems="center"
        justifyContent="center"
        direction={{ xs: "column", sm: "row" }}
      >
        {languagesMenu.map((language) => (
          <Grid item key={language}>
            <Button
              variant="contained"
              size="medium"
              onClick={() => onLanguageSelect(language)}
              style={{ whiteSpace: "nowrap" }}
            >
              {flags[language] + " " + language}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

let timer = null;

const GameInProgress = ({
  englishTranslation,
  setEnglishTranslation,
  setGameStage,
  clickedOnLanguages,
  setScore,
  score,
  englishWord,
  foreignWord,
  setRandomNumber,
  generateRandomNumber,
  setLanguagesMenu,
  setLevel,
  difficulty,
  selectedLanguage,
  setDifficulty,
  level,
  winGame,
  setSelectedLanguage,
  useAuthContext,
  useFirestore,
}) => {
  const { addDocument } = useFirestore("noun-translation-high-scores");
  const [seconds, setSeconds] = useState(10);
  const { user } = useAuthContext();

  useEffect(() => {
    if (!seconds) {
      user
        ? addDocument({
            score,
            level,
            uid: user.uid,
            displayName: user.displayName,
          })
        : addDocument({
            score,
            level,
            displayName: "---",
          });
      setGameStage("game-over");
    } else {
      timer = setTimeout(() => setSeconds((seconds) => seconds - 1), 1000);
    }
    // eslint-disable-next-line
  }, [seconds, setGameStage]);

  const checkEnglishTranslation = (e) => {
    setEnglishTranslation(e.target.value);
  };
  const isAnswerCorrect = englishTranslation.toLowerCase() === englishWord;
  const handleSubmit = () => {
    if (winGame) {
      return setGameStage("game-over");
    }
    clearTimeout(timer);
    setSeconds(10);

    if (isAnswerCorrect && score && !(score % 10)) {
      setScore((prevScore) => prevScore + 1);
      setLevel((prevLevel) => prevLevel + 1);
      setDifficulty(1);
      setRandomNumber(100 + generateRandomNumber());
      setLanguagesMenu(generateLanguagesArray(clickedOnLanguages));
      return setGameStage("language-selection");
    }
    if (isAnswerCorrect) {
      const randomlyChosenLanguage = Math.floor(
        Math.random() * clickedOnLanguages.length
      );
      setScore((prevScore) => prevScore + 1);
      setDifficulty((prevDifficulty) => prevDifficulty + 1);
      setRandomNumber(difficulty * 100 + generateRandomNumber());
      setSelectedLanguage(clickedOnLanguages[randomlyChosenLanguage]);
      return setEnglishTranslation("");
    }

    if (user) {
      addDocument({
        score,
        level,
        uid: user.uid,
        displayName: user.displayName,
      });
      return setGameStage("game-over");
    }

    addDocument({
      score,
      level,
      displayName: "---",
    });
    return setGameStage("game-over");
  };
  return (
    <>
      <Grid container item justifyContent="space-around" p={2}>
        <Grid item>
          <Typography variant="h6" color="gray">
            {`Score: ${score}`}
          </Typography>
        </Grid>
        <Grid>
          <Typography color="red" variant="h5">
            {seconds}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="gray">
            {`Languages: ${level}`}
          </Typography>
        </Grid>
      </Grid>
      <Typography>{`What 🇺🇸 English noun is the most accurate translation of this ${
        flags[selectedLanguage]
      } ${
        selectedLanguage.charAt(0).toUpperCase() + selectedLanguage.slice(1)
      } word?`}</Typography>
      <Grid container alignItems="center" direction="column">
        <Grid item>
          <Typography
            variant="h5"
            color="black"
            align="center"
            p={2}
            style={{ userSelect: "none" }}
          >
            {foreignWord}
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            margin="normal"
            id="translated word"
            label="Type Translation Here"
            align="center"
            onChange={checkEnglishTranslation}
            onKeyDown={(e) => {
              if (e.key === "Enter" && englishTranslation !== "") {
                handleSubmit();
              }
            }}
            value={englishTranslation}
          />
        </Grid>
        <Grid item>
          <Typography
            variant="h6"
            color="black"
            align="center"
            p={2}
            style={{ userSelect: "none" }}
          >
            PRESS "ENTER" KEY TO SUBMIT
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            color="red"
            sx={{ marginBottom: 5 }}
          >{`Hint: The correct word has ${
            englishWord.length
          } letters and begins with the letter '${englishWord.charAt(
            0
          )}'.`}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

const NounTranslation = () => {
  const [languagesMenu, setLanguagesMenu] = useState(generateLanguagesArray());

  const [gameStage, setGameStage] = useState("welcome");
  const [clickedOnLanguages, setClickedOnLanguages] = useState([]);
  const [score, setScore] = useState(0);
  const [level, setLevel] = useState(1);
  const winGame = score > 120;

  const [englishTranslation, setEnglishTranslation] = useState("");

  const [difficulty, setDifficulty] = useState(0);
  const generateRandomNumber = () => {
    const randomlyGeneratedNumber = Math.floor(Math.random() * 100);
    return randomlyGeneratedNumber;
  };
  const [randomNumber, setRandomNumber] = useState(generateRandomNumber());
  const [randomLanguageIndex, setRandomLanguageIndex] = useState(0);

  const [selectedLanguage, setSelectedLanguage] = useState("");

  const englishWord = words[randomNumber]["English"];
  const foreignWord = words[randomNumber][selectedLanguage];

  const [playMultipleGames, setPlayMultipleGames] = useState(false);

  const gameStages = {
    welcome: <Welcome setGameStage={setGameStage} />,
    "language-selection": (
      <LanguageSelection
        setGameStage={setGameStage}
        setClickedOnLanguages={setClickedOnLanguages}
        languagesMenu={languagesMenu}
        setLanguagesMenu={setLanguagesMenu}
        score={score}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        randomLanguageIndex={randomLanguageIndex}
        clickedOnLanguages={clickedOnLanguages}
        setRandomLanguageIndex={setRandomLanguageIndex}
        playMultipleGames={playMultipleGames}
        setEnglishTranslation={setEnglishTranslation}
      />
    ),
    "game-in-progress": (
      <GameInProgress
        setGameStage={setGameStage}
        clickedOnLanguages={clickedOnLanguages}
        selectedLanguage={selectedLanguage}
        score={score}
        setScore={setScore}
        englishWord={englishWord}
        foreignWord={foreignWord}
        randomNumber={randomNumber}
        generateRandomNumber={generateRandomNumber}
        setRandomNumber={setRandomNumber}
        setLanguagesMenu={setLanguagesMenu}
        level={level}
        setLevel={setLevel}
        difficulty={difficulty}
        setDifficulty={setDifficulty}
        winGame={winGame}
        setSelectedLanguage={setSelectedLanguage}
        setRandomLanguageIndex={setRandomLanguageIndex}
        useFirestore={useFirestore}
        useAuthContext={useAuthContext}
        englishTranslation={englishTranslation}
        setEnglishTranslation={setEnglishTranslation}
      />
    ),
    "game-over": (
      <GameOver
        score={score}
        level={level}
        clickedOnLanguages={clickedOnLanguages}
        setGameStage={setGameStage}
        setScore={setScore}
        setLevel={setLevel}
        setRandomNumber={setRandomNumber}
        setDifficulty={setDifficulty}
        generateRandomNumber={generateRandomNumber}
        setClickedOnLanguages={setClickedOnLanguages}
        winGame={winGame}
        loseText={`The correct 🇺🇸 English translation of the ${
          flags[selectedLanguage]
        } ${selectedLanguage} noun '${foreignWord}' is '${englishWord}'. Your answer was '${
          englishTranslation === "" ? "(no answer)" : englishTranslation
        }'.`}
        setLanguagesMenu={setLanguagesMenu}
        generateLanguagesArray={generateLanguagesArray}
        setPlayMultipleGames={setPlayMultipleGames}
        isNounTranslation={true}
      />
    ),
  };
  return (
    <>
      {" "}
      <Header />
      <Container maxWidth="sm">
        <Box py={15}>{gameStages[gameStage]}</Box>
      </Container>
      <Footer />
    </>
  );
};

export default NounTranslation;
