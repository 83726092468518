import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "gloskosmos.firebaseapp.com",
  projectId: "gloskosmos",
  storageBucket: "gloskosmos.appspot.com",
  messagingSenderId: "244282202265",
  appId: "1:244282202265:web:0dc5ee51c3242283b94008",
  measurementId: "G-YJCN98Z2VK",
};

//init firebase
firebase.initializeApp(firebaseConfig);

//init services
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();

//timestamp
const timestamp = firebase.firestore.Timestamp;

export { projectFirestore, projectAuth, timestamp };
