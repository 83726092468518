import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";

const EnglishData = () => {
  const summaryOfFindings = [
    "-Most English words are seven letters long.",
    "-The most common first letter of English words is 's' followed by 'c'.",
    "-By far the most common last letter of English words is 's' (due in part to the fact that most noun plurals end in 's'), followed by 'e'.",
    "-The most common letter found in English words is the vowel 'e', followed by the vowels 'a' and 'i'. The most common consonant is 's', followed by 'n', 'r' and 't'.",
    "-Many of the most common English words are articles, conjunctions, and prepositions. However, there are very few of these types of words in English, so as the list of commonly used words gets longer, most words on the list are instead nouns, verbs, and adjectives.",
  ];
  return (
    <div>
      <Header />
      <Container maxWidth="md">
        <Box py={2}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Typography
                variant="h4"
                color="black"
                align="center"
                sx={{ marginTop: 6 }}
              >
                ENGLISH WORD AND LETTER FREQUENCIES{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginBottom: 5 }}
              >
                AN ANALYSIS USING PYTHON (PANDAS AND MATPLOTLIB){" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h7"
                color="black"
                align="left"
                sx={{ fontStyle: "italic" }}
              >
                The following analysis is an excerpt of a{" "}
                <a href="https://www.kaggle.com/code/chrisloop89/english-words-and-letters-frequency/notebook">
                  more detailed analysis
                </a>{" "}
                (complete with the underlying code) from Kaggle (see also the
                original dataset{" "}
                <a href="https://www.kaggle.com/datasets/wheelercode/english-word-frequency-list">
                  here
                </a>
                ). The dataset and analysis are available under the{" "}
                <a href="https://www.apache.org/licenses/LICENSE-2.0">
                  Apache 2.0
                </a>{" "}
                open source license.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="center">
                - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
                - -
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black">
                The dataframe used in this analysis lists the{" "}
                <strong>20,000</strong> most common English words (roughly equal
                to the number of words that most native English speakers know)
                ranked by how often they appear in the Google Books repository.
                Based on these data, the <strong>20</strong> most frequent words
                in English (and the number of times they appear in the Google
                Books corpora) are shown in{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Table 1
                </Box>{" "}
                below:
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={4} xs={12}>
                <img
                  src="/images/Table1.png"
                  alt="table1"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                Word Length Analysis
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                It would be interesting to see how many letters the average
                English word has. For this, we create a new column in the
                dataset called "Word Length" that contains the number of letters
                in each of the <strong>20,000</strong> words, the top{" "}
                <strong>20</strong> of which are shown in{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Table 2
                </Box>{" "}
                below:
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={5} xs={12}>
                <img
                  src="/images/Table2.png"
                  alt="table2"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Using the Python mean() function, we can now find the average
                value of the "word length" column, and see that the average
                English word is <strong>7.27</strong> letters long, as shown in
                the code sample below:
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={5} xs={12}>
                {" "}
                <img
                  src="/images/codesample.png"
                  alt="codesample"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Making a bar chart of the most frequent values in the "word
                length" column shows the range of lengths of English words.{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Figure 1
                </Box>{" "}
                below shows that the most English words (<strong>2,961</strong>)
                are seven letters long (
                <Box component="span" sx={{ fontStyle: "italic" }}>
                  i.e.
                </Box>
                , the mode of the word length column is <strong>seven</strong>).
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={8} xs={12}>
                <img
                  src="/images/Figure1.png"
                  alt="figure1"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                The code below reveals some of the longest words in English that
                most native speakers know (there are of course much longer words
                that are more obscure). The two longest words in the top{" "}
                <strong>20,000</strong> most frequently used word list are{" "}
                <strong>"telecommunications"</strong> and{" "}
                <strong>"characteristically"</strong> (both have{" "}
                <strong>18</strong> letters).
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={6} xs={12}>
                <img
                  src="/images/codesample1.png"
                  alt="codesample1"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                First Letter of English Words{" "}
              </Typography>
            </Grid>
            <Grid item>
              It's also interesting to see which letter English words most
              frequently begin with. For this, we make another column in the
              dataset called "First Letter", as shown in{" "}
              <Box component="span" sx={{ textDecoration: "underline" }}>
                Table 3
              </Box>{" "}
              below.
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={5} xs={12}>
                {" "}
                <img
                  src="/images/Table3.png"
                  alt="table3"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              From here we make a bar chart{" "}
              <Box component="span" sx={{ textDecoration: "underline" }}>
                (Figure 2)
              </Box>{" "}
              showing the most common first letters of English words and note
              that the most commonly used words in English begin with the letter
              's' (<strong>2,089</strong>) followed by 'c' (
              <strong>2,038</strong>). On the other hand, only{" "}
              <strong>26</strong> commonly used words begin with 'z' and only{" "}
              <strong>20</strong> begin with 'x'.
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={7} xs={12}>
                {" "}
                <img
                  src="/images/Figure2.png"
                  alt="figure2"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                Last Letter of English Words{" "}
              </Typography>
            </Grid>
            <Grid item>
              From the dataset we can also determine which letters the most
              common English words end with. We do this by making another column
              in the dataset called "Last Letter", as shown in{" "}
              <Box component="span" sx={{ textDecoration: "underline" }}>
                Table 4
              </Box>{" "}
              below.
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={6} xs={12}>
                {" "}
                <img
                  src="/images/Table4.png"
                  alt="lastLetter"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              We then make a bar chart{" "}
              <Box component="span" sx={{ textDecoration: "underline" }}>
                (Figure 3)
              </Box>{" "}
              showing the most common last letters of English words and note
              that English words most frequently end with the letter 's' (
              <strong>4,196</strong>). This makes sense, as 's' is used to form
              the plural for most nouns in English (and because words derived
              from the same lemma have different entries in the database,{" "}
              <Box component="span" sx={{ fontStyle: "italic" }}>
                e.g.
              </Box>
              , 'house' is ranked <strong>182nd</strong> and 'houses' is ranked{" "}
              <strong>1,753rd</strong>). Interestingly, only <strong>12</strong>{" "}
              English words end in 'j' and <strong>7</strong> end in 'q'.
              However, after further analysis, words in the dataset that ended
              in these letters were actually all acronyms (
              <Box component="span" sx={{ fontStyle: "italic" }}>
                e.g.
              </Box>
              , "DJ" and "IQ").
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={7} xs={12}>
                {" "}
                <img
                  src="/images/Figure3.png"
                  alt="figure3"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                Most Common Letter in English Words{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Another interesting analysis is determining, based on the list,
                which letters of the English alphabet are the most frequently
                used. We can do this by creating a Python dictionary for each
                letter of the alphabet, where each letter is the key and the
                value is how many times the letter appears in the list of the{" "}
                <strong>20,000</strong> most common English words. From the
                newly created dictionary we can make another chart (
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Figure 4
                </Box>
                ) with two different colors to differentiate vowels (in red) and
                consonants (in green).
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={7} xs={12}>
                {" "}
                <img
                  src="/images/Figure4.png"
                  alt="figure4"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                The chart shows that 'e' is by far the most frequently occurring
                letter in the <strong>20,000</strong> most common English words,
                with <strong>17,160 </strong>
                occurrences. 'q' is the least frequently occurring (
                <strong>236</strong> are found in the top{" "}
                <strong>20,000</strong> words).
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                The graph also shows that 'e' (<strong>17,160</strong>), 'a' (
                <strong>11,511</strong>) and 'i' (<strong>12,623</strong>) are
                the most common vowels, and that 'o' (<strong>9,166</strong>)
                and 'u' (<strong>4,445</strong>) are not used as often.{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Note:
                </Box>{" "}
                'y', which is sometimes a vowel but also sometimes a consonant,
                only appears <strong>2,374</strong> times in the most common
                words so it is not used as nearly as often as the other vowels.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                The consonants 's' (<strong>11,052</strong>), 'n' (
                <strong>10,983</strong>), 'r' (<strong>10,857</strong>) and 't'
                (<strong>10,529</strong>) are the four consonants that appear by
                far the most frequently in the <strong>20,000</strong> most
                common English words.
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                Parts of Speech of English Words
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Another interesting analysis is seeing which parts of speech the
                most common words in English are. The dataset doesn't include
                parts of speech but we can add them manually (or through an API)
                for the top <strong>200</strong> words. With this new column we
                can make a couple pie charts with the results.
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                color="black"
                align="center"
                sx={{ textDecoration: "underline", marginTop: 5 }}
              >
                Parts of Speech of the Top 20 Most Used English Words{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                The pie chart{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  (Figure 5)
                </Box>{" "}
                below shows the parts of speech of the most common{" "}
                <strong>20</strong> words in English. (
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Note:
                </Box>{" "}
                Some of the words can be multiple parts of speech, such as 'as'
                and 'that'). <strong>Two articles</strong> in English (
                <Box component="span" sx={{ fontStyle: "italic" }}>
                  i.e.
                </Box>
                , 'the', and 'a') are included in the top 20 as are{" "}
                <strong>seven prepositions</strong> (
                <Box component="span" sx={{ fontStyle: "italic" }}>
                  i.e.
                </Box>
                , 'of', 'to', 'in', 'for', 'by', 'with', and 'on'),{" "}
                <strong>three conjunctions</strong> (
                <Box component="span" sx={{ fontStyle: "italic" }}>
                  i.e.
                </Box>
                , 'and', 'as' and 'or'), <strong>four verbs</strong> or
                derivations thereof (
                <Box component="span" sx={{ fontStyle: "italic" }}>
                  i.e.
                </Box>
                , 'is', 'be', 'was', and 'are'), <strong>two adjectives</strong>{" "}
                (
                <Box component="span" sx={{ fontStyle: "italic" }}>
                  i.e.
                </Box>
                , 'that' and 'this'), <strong> one pronoun</strong> (
                <Box component="span" sx={{ fontStyle: "italic" }}>
                  i.e.
                </Box>
                , 'it'), and <strong>one adverb</strong> (
                <Box component="span" sx={{ fontStyle: "italic" }}>
                  i.e.
                </Box>
                , 'not'). Interestingly, <strong>no nouns</strong> make up the{" "}
                <strong>20</strong> most common English words.
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={8} xs={12}>
                {" "}
                <img
                  src="/images/Figure5.png"
                  alt="figure5"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                color="black"
                align="center"
                sx={{ textDecoration: "underline", marginTop: 5 }}
              >
                Parts of Speech of the Top 200 Most Used English Words{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h8" color="black" align="left">
                When the list is expanded to the top <strong>200</strong> words,
                the pie chart{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  (Figure 6)
                </Box>{" "}
                changes significantly. Nouns, verbs, and adjectives now make up
                almost <strong>two thirds</strong> of the pie chart, whereas
                articles, prepositions, pronouns, and conjunctions make up{" "}
                <strong>the minority</strong>. This makes sense as in English
                there are thousands more nouns, verbs and adjectives (and the
                number of these word types grows each year) than there are
                pronouns, prepositions, articles, and conjunctions (whose
                numbers have generally remained steady over the years). Of note,
                the most common noun in the English language is "time" (which is
                the <strong>58th</strong> most common word overall).
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={8} xs={12}>
                {" "}
                <img
                  src="/images/Figure6.png"
                  alt="figure6"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 10 }}
              >
                SUMMARY OF FINDINGS{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left" p={2}>
                The most important findings from this analysis are the
                following:
              </Typography>
              <List>
                {summaryOfFindings.map((summary) => (
                  <ListItem key={summary}>
                    <ListItemText sx={{ marginLeft: 2 }} primary={summary} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </div>
  );
};
export default EnglishData;
