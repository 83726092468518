import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

const GameOver = ({
  score,
  level,
  setGameStage,
  setScore,
  setLevel,
  setDifficulty,
  setClickedOnLanguages,
  winGame,
  loseText,
  setRounds,
  setLossStreak,
  setLanguagesMenu,
  generateLanguagesArray,
  setPlayMultipleGames,
  isNounTranslation,
  isNounCombination,
  setLanguageDisplay,
}) => {
  return (
    <>
      <Grid container item justifyContent="center" p={2}>
        <Grid item>
          <Typography variant="h4" color="black" align="center" p={2}>
            {winGame ? `CONGRATULATIONS! ` : `GAME OVER`}
          </Typography>
          {winGame ? (
            <Typography variant="h5" color="black" align="center" p={2}>
              YOU HAVE WON THE GAME!
            </Typography>
          ) : (
            ""
          )}
          <Typography
            variant="h6"
            color="black"
            align="center"
            paddingBottom={3}
          >
            {winGame ? `You are a true polyglot!` : loseText}
          </Typography>
          <Typography variant="h6" color="black" align="center">
            {`Final Score: ${score}`}
          </Typography>
          <Typography
            variant="h6"
            color="black"
            align="center"
            paddingBottom={3}
          >
            {`Number of Languages: ${level}`}
          </Typography>
          <Typography variant="h5" color="black" align="center" p={2}>
            Thank you for playing!
          </Typography>
          <Grid container item justifyContent="space-around">
            <Grid item p={2}>
              <Button
                variant="contained"
                href="/"
                size="large"
                sx={{ marginBottom: 8 }}
              >
                Back to Home Page
              </Button>
            </Grid>
            <Grid item p={2}>
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  setScore(0);
                  setDifficulty(0);
                  // setNewGame(false);
                  setLanguagesMenu(generateLanguagesArray());
                  setClickedOnLanguages([]);
                  if (isNounCombination) {
                    setLanguageDisplay(true);
                    setRounds(0);
                    setLossStreak(0);
                    setLevel(2);
                    return setGameStage("game-in-progress");
                  }
                  if (isNounTranslation) {
                    setPlayMultipleGames(true);
                    setLevel(1);
                    return setGameStage("language-selection");
                  }
                  setLevel(3);
                  return setGameStage("language-selection");
                }}
                sx={{ marginBottom: 8 }}
              >
                Play Again
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default GameOver;
