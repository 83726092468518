import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const GermanData = () => {
  const theme = useTheme();

  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const summaryOfFindings = [
    "-Die meisten deutschen Wörter haben sechs Buchstaben (aber die durchschnittliche Länge eines Wortes ist sieben Buchstaben).",
    "-Der häufigste erste Buchstabe deutscher Wörter ist 's' (mit Abstand).",
    "-Der bei weitem häufigste letzte Buchstabe deutscher Wörter ist 'n'; (der der Endbuchstabe aller Verben in Infinitivform und vieler Substantive im Plural ist), gefolgt von 't', und 'e'.",
    "-In deutschen Wörtern der Buchstabe der am häufigsten vorkommt ist der Vokal 'e', gefolgt vom Konsonanten 'n'.",
    "-Viele der gebräuchlichsten deutschen Wörter sind Artikel, Pronomen, Konjunktionen und Präpositionen. Allerdings gibt es im Deutschen nur sehr wenige dieser Worttypen. Wenn die Liste also um weitere Wörter erweitert wird, werden Substantive, Verben und Adjektive allmählich die Mehrheit bilden.",
  ];
  return (
    <div>
      <Header />
      <Container maxWidth="md">
        <Box py={2}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Typography
                variant="h4"
                color="black"
                align="center"
                sx={{ marginTop: 6 }}
              >
                {isMedium
                  ? "DEUTSCHE WORT- UND BUCHSTABEN-HÄUFIGKEITEN"
                  : "DEUTSCHE WORT- UND BUCHSTABENHÄUFIGKEITEN"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginBottom: 5 }}
              >
                EINE ANALYSE MIT PYTHON (PANDAS UND MATPLOTLIB){" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h7"
                color="black"
                align="left"
                sx={{ fontStyle: "italic" }}
              >
                Den in dieser Analyse verwendeten Datensatz finden Sie{" "}
                <a href="https://en.wiktionary.org/wiki/Wiktionary:Frequency_lists/German/TV_and_movie_subtitles_(2009)">
                  hier
                </a>
                . Die Analyse ist unter der{" "}
                <a href="https://www.apache.org/licenses/LICENSE-2.0">
                  Apache 2.0
                </a>
                -Lizenz verfügbar.{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="center">
                - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
                - -
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black">
                Der in dieser Analyse verwendete DataFrame listet die{" "}
                <strong>20,000</strong> gebräuchlichsten deutschen Wörter auf
                (was ungefähr dem passiven Wortschatz eines Muttersprachlers
                entspricht) sortiert nach ihrer Vorkommenshäufigkeit in
                Untertiteln deutscher Filme und Fernsehsendungen bis 2009.{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Anmerkung
                </Box>
                : Die Liste enthält einzelne{" "}
                <a href="https://de.wikipedia.org/wiki/Lexem">Lexeme</a>{" "}
                (einschließlich Eigennamen - z.B. Ortsnamen und Vornamen) und
                großgeschriebene Wortformen werden getrennt von ihren
                kleingeschriebenen Gegenstücken aufgeführt. Für diese Analyse
                werden beide Formen eines einzelnen Wortes kombiniert und in
                einem einzigen Eintrag zusammengefasst. Auf Basis dieser Daten,
                die <strong>20</strong> gebräuchlichsten Wörter im Deutschen
                (und, in der rechten Spalte, ihre Vorkommenshäufigkeit) sind
                unten in{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Tabelle 1
                </Box>{" "}
                dargestellt:
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={6} xs={12}>
                <img
                  src="/images/de_table1.png"
                  alt="de_table1"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                Wortlängenanalyse{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Eine interessante Analyse ist die Bestimmung der
                durchschnittlichen Länge deutscher Wörter. Dafür erstellen wir
                im DataFrame eine neue Spalte namens "Wortlänge", die die Anzahl
                der Buchstaben in jedem der <strong>20.000</strong> Wörter
                enthält. Die ersten <strong>20</strong> Zeilen dieses DataFrames
                sind unten in{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Tabelle 2
                </Box>{" "}
                aufgeführt:
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={5} xs={12}>
                <img
                  src="/images/de_table2.png"
                  alt="_de_table2"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Mithilfe der Python-Funktion "mean()" können wir nun den
                Durchschnittswert der Spalte "Wortlänge" ermitteln. Wir sehen,
                dass das durchschnittliche deutsche Wort 7,27 Buchstaben lang
                ist, wie im folgenden Codebeispiel gezeigt:
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={5} xs={12}>
                {" "}
                <img
                  src="/images/de_codesample.png"
                  alt="de_codesample"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Ein Balkendiagramm mit den häufigsten Werten in der Spalte
                "Wortlänge" zeigt den Längenbereich deutscher Wörter an.{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Abbildung 1
                </Box>{" "}
                unten zeigt, dass die meisten deutschen Wörter (
                <strong>3.306</strong>) sechs Buchstaben lang sind (d.h. der
                Modus der Wortlängenspalte ist <strong>sechs</strong>).
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={8} xs={12}>
                <img
                  src="/images/de_figure1.png"
                  alt="de_figure1"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Der folgende Code zeigt einige der längsten deutschen Wörter,
                die die meisten Muttersprachler kennen (es gibt natürlich viele
                längere Wörter, die unbekannter sind). Die beiden längsten
                Wörter in der Liste der <strong>20.000</strong> gebräuchlichsten
                Wörter sind <strong>"Verteidigungsministerium"</strong> (
                <strong>24</strong> Buchstaben) und{" "}
                <strong>"Sicherheitsvorkehrungen"</strong> (<strong>23</strong>{" "}
                Buchstaben).
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={6} xs={12}>
                <img
                  src="/images/de_codesample1.png"
                  alt="de_codesample1"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                Erster Buchstabe deutscher Wörter{" "}
              </Typography>
            </Grid>
            <Grid item>
              Es ist auch interessant zu ermitteln, mit welchem Buchstaben
              deutsche Wörter am häufigsten beginnen. Dafür erstellen wir im
              Datensatz eine weitere Spalte namens "Erster Buchstabe", wie in
              der nachstehenden{" "}
              <Box component="span" sx={{ textDecoration: "underline" }}>
                Tabelle 3
              </Box>{" "}
              (die nur die ersten <strong>20</strong> Zeilen zeigt) dargestellt:
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={5} xs={12}>
                {" "}
                <img
                  src="/images/de_table3.png"
                  alt="de_table3"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              Von hier aus erstellen wir ein Balkendiagramm (
              <Box component="span" sx={{ textDecoration: "underline" }}>
                Abbildung 2
              </Box>
              ), das die häufigsten Anfangsbuchstaben deutscher Wörter zeigt und
              stellen fest, dass die <strong>20.000</strong> gebräuchlichsten
              Wörter im Deutschen am häufigsten mit dem Buchstaben "s" (
              <strong>2.195</strong>) beginnen, gefolgt von "a" (
              <strong>1.485</strong>) und "b" (<strong>1.475</strong>).
              Andererseits beginnen nur <strong>26</strong> gebräuchliche Wörter
              mit "ö" (z.B. "öfter" - #<strong>3.094</strong> in der Liste - und
              "öffnen" - #<strong>4.534</strong>) und nur <strong>sechs</strong>{" "}
              mit "x" (z.B. "X", der Name des Buchstabens - #
              <strong>4.406</strong> - und "Xavier", ein Vorname - #
              <strong>10.435</strong>). Außerdem beginnt <strong>kein</strong>{" "}
              Wort mit dem Eszett ("ß").
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={7} xs={12}>
                {" "}
                <img
                  src="/images/de_figure2.png"
                  alt="de_figure2"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                Letzter Buchstabe deutscher Wörter{" "}
              </Typography>
            </Grid>
            <Grid item>
              Aus dem Datensatz können wir auch ermitteln, mit welchen
              Buchstaben deutsche Wörter am häufigsten enden. Dies erreichen
              wir, indem wir im Datensatz eine weitere Spalte mit dem Namen
              "Letzter Buchstabe" erstellen, wie in der nachstehenden{" "}
              <Box component="span" sx={{ textDecoration: "underline" }}>
                Tabelle 4
              </Box>{" "}
              dargestellt:
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={6} xs={12}>
                {" "}
                <img
                  src="/images/de_table4.png"
                  alt="de_lastLetter"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              Wir erstellen nun ein Balkendiagramm (
              <Box component="span" sx={{ textDecoration: "underline" }}>
                Abbildung 3
              </Box>
              ), das die häufigsten letzten Buchstaben deutscher Wörter zeigt
              und stellen fest, dass die <strong>20.000</strong>{" "}
              gebräuchlichsten Wörter im Deutschen (<strong>5.230</strong>) am
              häufigsten mit dem Buchstaben "n" enden. Das macht Sinn, da alle
              deutsche Verben im Infinitiv und viele Pluralformen weiblicher
              Substantive mit diesem Buchstaben enden. Interessanterweise endet
              nur ein Wort mit "ö" und "ü" (jeweils die Interjektion "nö" - #
              <strong>7.201</strong> - und das Substantiv "Menü" - #
              <strong>15.553</strong>).
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={7} xs={12}>
                {" "}
                <img
                  src="/images/de_figure3.png"
                  alt="figure3"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                Die gebräuchlichsten Buchstaben in deutschen Wörtern
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Eine weitere interessante Analyse besteht darin, anhand des
                DataFrames die häufigsten Buchstaben des deutschen Alphabets
                festzustellen. Wir können dies tun, indem wir für jeden
                Buchstaben des Alphabets ein Python-Wörterbuch erstellen. Dabei
                entspricht der Schlüssel einem bestimmten Buchstaben und der
                Wert entspricht der Vorkommenshäufigkeit des Buchstabens in der
                Liste der <strong>20.000</strong> gebräuchlichsten Wörter. Aus
                dem neu erzeugten Wörterbuch können wir ein weiteres Diagramm (
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Abbildung 4
                </Box>
                ) mit zwei verschiedenen Farben erstellen, um Vokale (in Rot)
                und Konsonanten (in Grün) zu unterscheiden.
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={7} xs={12}>
                {" "}
                <img
                  src="/images/de_figure4.png"
                  alt="de_figure4"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Abbildung 4
                </Box>{" "}
                zeigt, dass "e" mit <strong>22.859</strong> Vorkommen bei weitem
                der häufigste Buchstabe in der Liste der <strong>20.000</strong>{" "}
                gebräuchlichsten Wörter ist. "q" kommt am seltensten vor (nur{" "}
                <strong>66</strong> finden sich in der Liste).
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Unter Vokalen,{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Abbildung 4
                </Box>{" "}
                zeigt auch, dass "e" (<strong>22.859</strong> Vorkommen), "i" (
                <strong>9.048</strong>) und "a" (<strong>8.864</strong>) die
                häufigsten Vokale sind und dass, im Vergleich, der Vokal "y" (
                <strong>666</strong>) und Vokale mit Umlaut, (z.B. "ö" -
                <strong>494</strong>) nicht annähernd so oft vorkommen.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Die Konsonanten "n" (<strong>12.888</strong> Vorkommen), "r" (
                <strong>11.053</strong>), "t" (<strong>10.240</strong>) und "s"
                (<strong>9.153</strong>) sind die vier Konsonanten, die in den{" "}
                <strong>20.000</strong> gebräuchlichsten deutschen Wörtern
                weitaus am häufigsten vorkommen.
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 5 }}
              >
                Wortarten deutscher Wörter{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Eine abschließende Analyse können wir durchführen, indem wir uns
                die Wortarten der gebräuchlichsten deutschen Wörter ansehen. Der
                Datensatz enthält keine Wortarten. Wir können sie jedoch manuell
                (oder über eine API) für die häufigsten <strong>500</strong>{" "}
                Wörter hinzufügen. Mit dieser neuen Spalte können wir ein paar
                Kreisdiagramme mit den Ergebnissen erstellen.
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                color="black"
                align="center"
                sx={{ textDecoration: "underline", marginTop: 5 }}
              >
                Wortarten der 20 gebräuchlichsten deutschen Wörter{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left">
                Das Kreisdiagramm{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  (Abbildung 5)
                </Box>{" "}
                unten zeigt die Wortarten der <strong>20</strong> häufigsten
                Wörter im Deutschen. (
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  Anmerkung:
                </Box>{" "}
                Einige Wörter können als mehrere Wortarten gelten, wie z.B.
                'die' und 'wie'). <strong>Fünf Artikel</strong> oder Ableitungen
                davon im Deutschen ('das', 'die', 'der', 'ein', und 'den')
                finden sich in den oberen <strong>20</strong> Wörtern so wie{" "}
                <strong>zwei Konjunktionen</strong> ('und' und 'wie'),{" "}
                <strong>drei Präpositionen</strong> ('zu', 'in', und 'mit'),{" "}
                <strong>eine Verbableitung</strong> ('ist'),{" "}
                <strong>acht Pronomen</strong> oder Ablietungen davon ('ich',
                'sie', 'du', 'es', 'was', 'wir', 'er', und 'mir'), und{" "}
                <strong>ein Adverb</strong> ('nicht'). Interessanterweise
                befindet sich <strong>kein Substantiv</strong> unter den{" "}
                <strong>20</strong> häufigsten deutschen Wörtern.
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={8} xs={12}>
                {" "}
                <img
                  src="/images/de_figure5.png"
                  alt="de_figure5"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                color="black"
                align="center"
                sx={{ textDecoration: "underline", marginTop: 5 }}
              >
                Wortarten der 500 gebräuchlichsten deutschen Wörter{" "}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h8" color="black" align="left">
                Wenn die Liste auf <strong>500</strong> Wörter erweitert wird,
                ändert sich das Kreisdiagramm{" "}
                <Box component="span" sx={{ textDecoration: "underline" }}>
                  (Abbildung 6)
                </Box>{" "}
                erheblich. Substantive, Verben, Adverbien und Adjektive machen
                mittlerweile fast <strong>drei Viertel</strong> des
                Kreisdiagramms aus, während Artikel, Präpositionen, Pronomen und
                Konjunktionen <strong>die Minderheit</strong> ausmachen. Das
                macht sinn, da es im Deutschen Tausende mehr Substantive,
                Verben, Adverbien, und Adjektive gibt (und die Anzahl dieser
                Worttypen jedes Jahr zunimmt) als Pronomen, Präpositionen,
                Artikel und Konjunktionen (deren Anzahl im Zeitverlauf im
                Allgemeinen konstant ist). Bemerkenswert ist, dass das häufigste
                Substantiv in der deutschen Sprache "Mann" ist (das insgesamt{" "}
                <strong>103</strong>. häufigste Wort).
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Grid item md={8} xs={12}>
                {" "}
                <img
                  src="/images/de_figure6.png"
                  alt="figure6"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                sx={{ marginTop: 10 }}
              >
                ZUSAMMENFASSUNG DER ERGEBNISSE
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h7" color="black" align="left" p={2}>
                Die wichtigsten Erkenntnisse dieser Analyse sind die folgenden:
              </Typography>
              <List>
                {summaryOfFindings.map((summary) => (
                  <ListItem key={summary}>
                    <ListItemText sx={{ marginLeft: 2 }} primary={summary} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </div>
  );
};
export default GermanData;
