import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";
import Button from "@mui/material/Button";
import { useAuthContext } from "../hooks/useAuthContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function Header() {
  const { logout } = useLogout();
  const { user } = useAuthContext();

  const theme = useTheme();

  const isMedium = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <React.Fragment>
      <CssBaseline />
      <Box sx={{ bgcolor: "black" }} p={2}>
        <Grid
          container
          justifyContent="space-between"
          wrap="nowrap"
          alignItems="center"
        >
          <Grid item md={6} xs={12}>
            <Link to="/" style={{ textDecoration: "none" }}>
              {isMedium ? (
                <img
                  src="/images/GKlogoMobile.png"
                  alt="gklogo"
                  style={{ width: "40%" }}
                />
              ) : (
                <img
                  src="/images/GKlogoDesktop.png"
                  alt="gklogo"
                  style={{ width: "40%" }}
                />
              )}
            </Link>
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end" spacing={2}>
              {!user && (
                <>
                  <Grid item>
                    <Button variant="contained" href="/login">
                      Login
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" href="/signup">
                      Sign Up
                    </Button>
                  </Grid>
                </>
              )}
              {user && (
                <>
                  <Grid item>
                    <Typography color="white">
                      Welcome, {user.displayName}!{" "}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" onClick={logout}>
                      Logout
                    </Button>{" "}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
