import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PopperButton from "./PopperButton";
import Box from "@mui/material/Box";

export default function Main() {
  return (
    <Box py={4}>
      <Grid container spacing={5} p={10}>
        <Grid
          container
          item
          direction="column"
          spacing={2}
          sm={6}
          alignItems="center"
        >
          <Grid item>
            <Typography
              variant="h3"
              color="black"
              marginTop={7}
              marginBottom={7}
              textAlign="center"
            >
              Welcome to the World of Languages
            </Typography>
          </Grid>
          <Grid
            container
            item
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            justifyContent={{ xs: "center", sm: "space-around" }}
            alignItems="center"
          >
            <Grid item>
              <PopperButton
                label="quizzes"
                menuItems={[
                  "Language Families and Geography",
                  "Writing Systems and Phonology",
                  "Grammar and Morphology",
                ]}
              />
            </Grid>
            <Grid item>
              <PopperButton
                label="data"
                menuItems={[
                  "English Word Analysis",
                  "Анализ Pусских Cлов",
                  "تحليل الكلمات العربية",
                  "Analyse des Mots Français",
                  "Deutsche Wortanalyse",
                ]}
              />
            </Grid>
            <Grid item>
              <PopperButton
                label="games"
                menuItems={[
                  "Polyglot Challenge: Word Translation",
                  "Polyglot Challenge: Word Association",
                  "Polyglot Challenge: Word Combination",
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item md={6} xs={12} justifyContent="center">
          <Grid item>
            <img
              src="/images/logo.png"
              alt="gloskosmoslogo"
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
