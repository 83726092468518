import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function ScoresList({ scores }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <strong>Score</strong>
            </TableCell>
            <TableCell align="center">
              <strong>Languages</strong>
            </TableCell>
            <TableCell align="center">
              <strong>User</strong>
            </TableCell>
            <TableCell align="center">
              <strong>Date</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {scores.map((score) => (
            <TableRow
              key={score.id}
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
                "white-space": "nowrap",
              }}
            >
              <TableCell align="center">{score.score}</TableCell>
              <TableCell align="center">{score.level}</TableCell>
              <TableCell align="center">{score.displayName}</TableCell>
              <TableCell align="center">
                {score.createdAt.toDate().toDateString().slice(4)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
