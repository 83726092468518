import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import GavelIcon from "@mui/icons-material/Gavel";
import Button from "@mui/material/Button";
import questions from "../components/data/grammarQuizQuestions.json";
import { useState } from "react";
import { useFirestore } from "../hooks/useFirestore";
import { useAuthContext } from "../hooks/useAuthContext";

const GrammarSummaries = [
  "Word formation, inflection, and morpho-syntactic alignments",
  "Clauses, predicates, and other syntactical structures",
  "Parts of speech and lexical categories",
];

const GrammarQuiz = () => {
  const { addDocument } = useFirestore("grammar-quiz-scores");
  const { user } = useAuthContext();
  const [showQuizQuestions, setShowQuizQuestions] = useState(false);
  const handleClick = () => {
    if (questionNumber === 10) {
      user
        ? addDocument({
            score,
            uid: user.uid,
            displayName: user.displayName,
          })
        : addDocument({
            score,
            displayName: "---",
          });
    }
    setShowQuizQuestions(true);
    setSelectedAnswer({});
    setQuestionIndexesArray((currentArray) => {
      const checkAndSetNewIndex = () => {
        const newRandomIndex = generateRandomNumber();
        if (currentArray.includes(newRandomIndex)) {
          return checkAndSetNewIndex();
        } else {
          return newRandomIndex;
        }
      };
      const newQuestionIndex = checkAndSetNewIndex();
      return [...currentArray, newQuestionIndex];
    });
  };

  const questionAnswered = (answer) => {
    setSelectedAnswer(answer);
    if (answer.correct) {
      setScore((prevScore) => prevScore + 1);
    }
  };

  const restartQuiz = () => {
    setShowQuizQuestions(true);
    setSelectedAnswer({});
    setQuestionIndexesArray([generateRandomNumber()]);
    setScore(0);
  };

  const endGameMessage = (score) => {
    if (score <= 5) {
      return "Thanks for playing!";
    } else if (score <= 8) {
      return "Nice job! You have a solid knowledge base of the grammar and morphology of world languages.";
    } else {
      return "Very impressive! You are a veritable morphologist.";
    }
  };

  const generateRandomNumber = () => {
    const randomNumber = Math.floor(Math.random() * questions.length);
    return randomNumber;
  };

  const [selectedAnswer, setSelectedAnswer] = useState({});
  const [score, setScore] = useState(0);
  const [questionIndexesArray, setQuestionIndexesArray] = useState([]);
  const questionNumber = questionIndexesArray.length;

  const { question, answers = [] } =
    questions[questionIndexesArray[questionIndexesArray.length - 1]] || {};
  const correctAnswerIndex = answers.findIndex(({ correct }) => correct);

  return (
    <div>
      <Header />
      <Box p={2}>
        <Grid
          container
          spacing={2}
          p={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h4" color="black" align="center" p={6}>
              Grammar and Morphology Quiz
            </Typography>
            {!showQuizQuestions && questionNumber < 11 && (
              <Typography variant="h5" color="black" align="left" p={2}>
                Test your knowledge of:
              </Typography>
            )}
          </Grid>
          {!showQuizQuestions && questionNumber < 11 && (
            <Grid item>
              <List>
                {GrammarSummaries.map((summary) => (
                  <ListItem key={summary}>
                    <GavelIcon />
                    <ListItemText sx={{ marginLeft: 2 }} primary={summary} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}
          {showQuizQuestions && questionNumber < 11 && (
            <Grid container maxWidth="md" direction="column" spacing={2}>
              <Grid container item justifyContent="space-around">
                <Grid item>
                  <Typography variant="h6" color="gray">
                    {`Score: ${score}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6" color="gray">
                    {`Question ${questionNumber} of 10`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="h5" color="black" align="center" p={2}>
                  {question}
                </Typography>
              </Grid>
              <Grid
                container
                item
                direction={{ xs: "column", sm: "row" }}
                justifyContent="center"
                alignItems="center"
                spacing={2}
                marginBottom={4}
                marginTop={3}
              >
                {answers.map((answer, index) => (
                  <Grid item key={index}>
                    <Button
                      onClick={() => questionAnswered(answer)}
                      disabled={!!selectedAnswer.text}
                      variant="contained"
                      size="large"
                      style={{ textTransform: "none" }}
                    >
                      {answer.text}
                    </Button>
                  </Grid>
                ))}
              </Grid>
              <Grid item marginBottom={5}>
                <Typography variant="h6" color="black" align="center" p={2}>
                  {selectedAnswer.explanation}
                </Typography>
                {!selectedAnswer.correct && selectedAnswer.text && (
                  <Typography variant="h6" color="block" align="center">
                    {`The correct answer is: ${answers[correctAnswerIndex].text}`}
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
          {showQuizQuestions && questionNumber >= 11 && (
            <Grid item>
              <Typography
                variant="h5"
                color="black"
                align="center"
                p={2}
              >{`Your score is: ${score}/10`}</Typography>
              <Typography variant="h6" color="black" align="center" p={2}>
                {endGameMessage(score)}
              </Typography>
            </Grid>
          )}
          <Grid item>
            {!showQuizQuestions && (
              <Button
                variant="contained"
                onClick={handleClick}
                size="large"
                sx={{ marginBottom: 8 }}
              >
                Start Quiz
              </Button>
            )}
            {showQuizQuestions && questionNumber <= 10 && (
              <Button
                variant="contained"
                onClick={handleClick}
                size="large"
                sx={{ marginBottom: 8 }}
                disabled={!selectedAnswer.text}
              >
                {questionNumber < 10 ? "Next" : "Finish"}
              </Button>
            )}
            {questionNumber > 10 && (
              <Grid container item justifyContent="space-around">
                <Grid item p={2}>
                  <Button
                    variant="contained"
                    href="/"
                    size="large"
                    sx={{ marginBottom: 8 }}
                  >
                    Back to Home Page
                  </Button>
                </Grid>
                <Grid item p={2}>
                  <Button
                    variant="contained"
                    onClick={restartQuiz}
                    size="large"
                    sx={{ marginBottom: 8 }}
                  >
                    Play Again
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </div>
  );
};

export default GrammarQuiz;
